<template>
  <div class="bodyheight">
    <v-container class="container-custom">
      <button type="button" @click="submitPayment">Submit</button>
      <div class="paymentscreen">
        <v-progress-circular
          color="primary"
          indeterminate
          v-if="loading"
        ></v-progress-circular>
        <h2>{{ titleMessage }}</h2>
      </div>
      <div v-if="allPaymentToken" class="paymentscreen">
        <ul>
          <li>
            Payment has been
            <strong style="text-transform: lowercase">{{
              allPaymentToken.results.data.Status
            }}</strong>
          </li>
          <li>
            Amount:
            <strong>Rs.{{ allPaymentToken.results.data.Amount }}</strong>
          </li>
          <li>
            Service Charage:
            <strong>Rs.{{ allPaymentToken.results.data.ServiceCharge }}</strong>
          </li>
          <li>
            Bank:
            <strong>{{ allPaymentToken.results.data.Institution }}</strong>
          </li>
          <li>
            Exchange Rate:
            <strong>Rs.{{ allPaymentToken.results.data.ExchangeRate }}</strong>
          </li>
          <li>
            Remark:
            <strong>{{
              allPaymentToken.results.data.TransactionRemarks
            }}</strong>
          </li>
          <li>
            Transaction Date:
            <strong>
              {{
                allPaymentToken.results.data.TransactionDate
                  | moment("YYYY-MM-D hh:mm:ss a")
              }}</strong
            >
          </li>
        </ul>
      </div>

      <v-snackbar v-model="snackbar" :timeout="3000">{{ message }}</v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NepalPaymentScreen",
  data() {
    return {
      valid: false,
      merchantName: "necojobs",
      merchantId: "310",
      merchantTxnId: "Trnx UAT1235",
      amount: "100",
      instrumentCode: "",
      transactionRemarks: "test checkout gateway",
      signature: undefined,
      processId: undefined,
      token: undefined,
      loading: true,
      snackbar: false,
      message: "",
      titleMessage: "You payment is processing. Please Wait !",
    };
  },

  methods: {
    ...mapActions([
      "getNepalPayment",
      "sendEnquiry",
      "getPaymentToken",
      "resetState",
    ]),
    submitPayment() {
      var path = "https://gateway.nepalpayment.com/payment/index";
      let data = {
        MerchantId: this.merchantId,
        MerchantName: this.merchantName,
        MerchantTxnId: this.merchantTxnId,
        Amount: this.amount + ".00",
        ProcessId: this.processId,
        InstrumentCode: this.instrumentCode,
        TransactionRemarks: this.transactionRemarks,
        Signature: this.signature,
      };

      var params = data;
      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);

      for (var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },
  },
  computed: {
    ...mapGetters(["allNepalPayment", "allPaymentToken"]),
  },
  mounted() {
    console.log("Payment Screen");
  },
  async created() {
    this.resetState();
    if (this.$route.query.MerchantTxnId) {
      this.titleMessage =
        "Your payment has been successfully processed. Please wait as we finalize the transaction";
      await this.getPaymentToken(this.$route.query.MerchantTxnId);
      if (this.allPaymentToken.results.data.Status == "Success") {
        this.snackbar = true;
        this.message = "Payment Successful! We have received your payment .";
      } else {
        this.snackbar = true;
        this.message =
          "We were unable to process your payment. Please try again.";
      }
      this.loading = false;
      this.titleMessage =
        "Please wait... we are redirecting you to the course page.";
      setTimeout(() => {
        if (this.allPaymentToken.results.slug !== "subscriptions") {
          this.$router.push(`/course/${this.allPaymentToken.results.slug}`);
        } else {
          this.$router.push(`/videocourses`);
        }
      }, 3000);
    } else {
      this.amount = this.$route.query.amount;
      await this.getNepalPayment({
        amount: this.amount + ".00",
        fullName: this.$route.query.fullName,
        email: this.$route.query.email,
        phone: this.$route.query.phone,
        subject: this.$route.query.subject,
        trainingId: this.$route.query.trainingId,
        slug: this.$route.query.slug,
      });
      if (this.allNepalPayment.status == 200) {
        this.signature = this.allNepalPayment.data.results.signature;
        this.merchantTxnId = this.allNepalPayment.data.results.txnId;
        this.token = this.allNepalPayment.data.results.token;
        this.processId = this.allNepalPayment.data.results.processId;
        this.transactionRemarks = this.$route.query.subject;
        setTimeout(() => {
          this.submitPayment();
          this.loading = false;
        }, 2000);
      } else {
        this.snackbar = true;
        this.message = this.allNepalPayment.data.message;
        this.titleMessage =
          this.allNepalPayment.data.message +
          " Please wait..." +
          " we are redirecting you to the course page.";
        this.loading = false;
        setTimeout(() => {
          if (this.$route.query.slug !== "subscriptions") {
            this.$router.push(`/course/${this.$route.query.slug}`);
          } else {
            this.$router.push(`/videocourses`);
          }
        }, 3000);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.bodyheight {
  height: 90vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paymentscreen {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 25px 0;
  background-color: #eeeeee;
  ul {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 45px;
    line-height: 25px;
  }
  h4 {
    padding-bottom: 15px;
    font-size: 25px;
  }
}
</style>
